import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { createGlobalStyle } from "styled-components"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import CourseHero from "../components/CourseHero"
import Img from "gatsby-image"
import EventItem from "../components/EventItem"
import scrollTo from "gatsby-plugin-smoothscroll"

export const query = graphql`
  query($slug: String!) {
    contentfulCourse(slug: { eq: $slug }) {
      courseId
      courseName
      courseContent {
        json
      }
      targetGroups {
        json
        targetGroups
      }
      eventsImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      contentImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      targetGroupsImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    heroImage: contentfulAsset(title: { eq: "Course Hero Bricks" }) {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    eventImage: contentfulAsset(title: { eq: "Events Default Image" }) {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    contentImage: contentfulAsset(
      title: { eq: "Course Content Default Image" }
    ) {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    targetImage: contentfulAsset(title: { eq: "Target Groups Default Image" }) {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    events: allContentfulEvent(
      sort: { fields: [city, startDate], order: [ASC, ASC] }
    ) {
      edges {
        node {
          courseId
          courseType
          city
          startDate(formatString: "dddd, DD. MMMM YYYY", locale: "de")
          endDate(formatString: "dddd, DD. MMMM YYYY", locale: "de")
          startDateSelect: startDate(formatString: "dd, DD.MM.YY", locale: "de")
          endDateSelect: endDate(formatString: "dd, DD.MM.YY", locale: "de")
          originalStartDate: startDate
        }
      }
    }
  }
`

const GlobalStyle = createGlobalStyle`
/* body {
  background-color: #f7f7f7;
} */
`

const StyledCourse = styled.div`
  h2 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .target-container {
    background-color: var(--primary);
    color: white;
  }

  .target-image {
    transform: scaleX(-1);
  }

  button {
    background-color: var(--primary);
    color: white;

    &:hover {
      color: white;
      background-color: black;
    }
  }

  .event-container {
    display: flex;
    flex-direction: column;

    .city-events {
      & > div {
        padding: 0.5rem;
        &:hover {
          box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.2);
          z-index: 100;
        }
      }

      & > div:nth-child(even) {
        background-color: #f3f3f3;
      }
      & > div:nth-child(odd) {
        background-color: #f9f9f9;
      }
    }
  }

  .no-events {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      font-size: 5rem;
      color: gray;
    }
  }
`

const emptyTargetGroup =
  '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'

const Course = props => {
  const events = props.data.events.edges.filter(node => {
    return (
      node.node.courseType.startsWith(
        props.data.contentfulCourse.courseName,
        0
      ) &&
      Date.parse(node.node.originalStartDate) + 60 * 60 * 24 * 1000 >=
        Date.now()
    )
  })

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item.node[key]]: [...(result[item.node[key]] || []), item.node],
      }),
      {}
    )

  const cityEvents = groupBy(events, "city")

  const citiesArray = []

  for (const key in cityEvents) {
    if (cityEvents.hasOwnProperty(key)) {
      citiesArray.push(cityEvents[key])
    }
  }

  const [inputCourseId, setInputCourseId] = useState("0")
  const [inputCourseDate, setInputCourseDate] = useState("0")
  const [inputCourseCity, setInputCourseCity] = useState("0")

  const handleClick = e => {
    e.preventDefault()
    const courseId = e.currentTarget.dataset.courseid
    setInputCourseId(courseId)
    const courseDate = e.currentTarget.dataset.coursedate
    setInputCourseDate(courseDate)
    const courseCity = e.currentTarget.dataset.coursecity
    setInputCourseCity(courseCity)
    scrollTo("#register")
  }

  const handleChange = e => {
    setInputCourseId(e.target.value)
    setInputCourseDate(
      e.target.options[e.target.options.selectedIndex].dataset["date"]
    )
    setInputCourseCity(
      e.target.options[e.target.options.selectedIndex].dataset["city"]
    )
  }

  return (
    <Layout>
      <GlobalStyle />
      <SEO title={props.data.contentfulCourse.courseName} />
      <StyledCourse>
        <CourseHero
          title={props.data.contentfulCourse.courseName}
          image={props.data.heroImage.fluid}
        />
        {/* Events */}
        <div className="row w-100 g-0">
          <div className="col-12 col-md-6 px-md-5 pb-5 p-3 p-md-0">
            <h2 className="mt-5">Termine</h2>
            <p className="lead">
              Wählen Sie einen der folgenden Termine aus, um ihn für die
              Anmeldung zu übernehmen:
            </p>
            <div className="event-container">
              {citiesArray.map((city, index) => {
                return (
                  <div className="city-events" key={`${city}-${index}`}>
                    <h3 className="my-3">{city[0].city}</h3>
                    {city.map(event => (
                      <EventItem
                        key={event.courseId}
                        data={event}
                        onClick={handleClick}
                      />
                    ))}
                  </div>
                )
              })}
              {citiesArray.length === 0 && (
                <div className="no-events">
                  <div className="no-events__icon">
                    <i className="fa fa-calendar-times" aria-hidden="true"></i>
                  </div>
                  <div className="no-events__text">
                    Momentan stehen keine Termine fest.
                  </div>
                </div>
              )}
              {/* {events.map(node => {
                return <EventItem key={node.node.courseId} data={node.node} />
              })} */}
            </div>
          </div>
          <div className="col-12 col-md-6 g-0">
            {props.data.contentfulCourse.eventsImage && (
              <Img
                fluid={props.data.contentfulCourse.eventsImage.fluid}
                draggable={false}
              />
            )}
            {!props.data.contentfulCourse.eventsImage && (
              <Img fluid={props.data.eventImage.fluid} draggable={false} />
            )}
          </div>
        </div>
        {/* Content */}
        <div className="row w-100 g-0">
          <div className="col-12 col-md-6 g-0">
            {props.data.contentfulCourse.contentImage && (
              <Img
                fluid={props.data.contentfulCourse.contentImage.fluid}
                draggable={false}
              />
            )}
            {!props.data.contentfulCourse.contentImage && (
              <Img fluid={props.data.contentImage.fluid} draggable={false} />
            )}
          </div>
          <div className="col-12 col-md-6 px-md-5">
            <div className="content container pt-5">
              <h2>Inhalte von {props.data.contentfulCourse.courseName}</h2>
              {documentToReactComponents(
                props.data.contentfulCourse.courseContent?.json
              )}
            </div>
          </div>
        </div>
        {/* Target Group */}
        {!props.data.contentfulCourse.targetGroups ||
          (props.data.contentfulCourse.targetGroups.targetGroups !==
            emptyTargetGroup && (
            <div className="row w-100 g-0">
              <div className="col-12 col-md-6 px-md-5 target-container">
                <div className="target container pt-5">
                  <h2>Zielgruppen</h2>
                  {documentToReactComponents(
                    props.data.contentfulCourse.targetGroups.json
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 g-0 target-image">
                {props.data.contentfulCourse.targetGroupsImage && (
                  <Img
                    fluid={props.data.contentfulCourse.targetGroupsImage.fluid}
                    draggable={false}
                  />
                )}
                {!props.data.contentfulCourse.targetGroupsImage && (
                  <Img fluid={props.data.targetImage.fluid} draggable={false} />
                )}
              </div>
            </div>
          ))}
        {/* Form */}
        <div className="row w-100 g-0 pt-5" id="register">
          <div className="col-12">
            <div className="container">
              <h2>Kursanmeldung</h2>
              <form
                className="row g-3 mb-5"
                name="Kursanmeldung"
                action="/kursanmeldung/erfolgreich"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Kursanmeldung" />

                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">Vorname</span>
                    <input
                      type="text"
                      aria-label="Vorname"
                      name="vorname"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">Nachname</span>
                    <input
                      type="text"
                      aria-label="Nachname"
                      name="nachname"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">E-Mail-Adresse</span>
                    <input
                      type="email"
                      name="email"
                      aria-label="E-Mail-Adresse"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">Telefonnummer</span>
                    <input
                      type="tel"
                      name="telefon"
                      aria-label="Telefonnummer"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">Kursnummer</span>
                    <select
                      aria-label="Kursnummer"
                      className="form-select"
                      name="kursnummer"
                      value={inputCourseId}
                      onChange={handleChange}
                    >
                      <option value="0" disabled>
                        Wählen Sie einen Kurs
                      </option>
                      {events.map(node => (
                        <option
                          key={node.node.courseId}
                          value={node.node.courseId}
                          data-date={`${node.node.startDate} – ${node.node.endDate}`}
                          data-city={node.node.city}
                        >
                          {node.node.courseId} ({node.node.startDateSelect} –{" "}
                          {node.node.endDateSelect} in {node.node.city})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <input type="hidden" name="datum" value={inputCourseDate} />
                <input type="hidden" name="kursort" value={inputCourseCity} />
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">Firma</span>
                    <input
                      type="text"
                      aria-label="Firma"
                      name="firma"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <span className="input-group-text">Straße</span>
                    <input
                      type="text"
                      aria-label="Straße"
                      name="straße"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <span className="input-group-text">PLZ</span>
                    <input
                      type="text"
                      aria-label="PLZ"
                      name="plz"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <span className="input-group-text">Ort</span>
                    <input
                      type="text"
                      aria-label="Ort"
                      name="ort"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="terms"
                      id="terms"
                      required
                    />
                    <label className="form-check-label" htmlFor="terms">
                      Sie erklären sich damit einverstanden, dass Ihre Daten zur
                      Bearbeitung Ihrer Anmeldung verwendet werden. Weitere
                      Informationen und Widerrufshinweise finden Sie in unserer
                      Datenschutzerklärung. Eine Kopie Ihrer Nachricht wird an
                      Ihre E-Mail-Adresse geschickt.
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-block">
                    Anmelden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </StyledCourse>
    </Layout>
  )
}

Course.propTypes = {
  data: PropTypes.object,
}

export default Course
