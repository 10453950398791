import React from "react"
import styled from "styled-components"

const StyledEventItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  cursor: pointer;

  .course-id {
    /* text-align: right; */
    .booking-link {
      color: var(--primary);
    }
  }
`

const EventItem = ({
  data: { courseId, courseType, city, startDate, endDate },
  onClick,
}) => {
  return (
    <StyledEventItem
      data-courseid={courseId}
      data-coursedate={`${startDate} – ${endDate}`}
      data-coursecity={city}
      onClick={onClick}
    >
      <div className="date">
        {startDate} – {endDate}
      </div>
      <div className="course-id">
        Kursnummer: <span className="booking-link">{courseId}</span>
      </div>
    </StyledEventItem>
  )
}

export default EventItem
