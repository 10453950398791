import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledCourseHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  .background {
    width: 100vw;
    user-select: none;
    position: relative;
    height: 700px;

    .gatsby-image-wrapper {
      height: 100%;
      object-fit: cover;
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    text-shadow: rgba(0, 0, 0, 0.3) 5px 5px 5px;
    position: absolute;

    @media (max-width: 425px) {
      font-size: 2rem;
    }
  }
`

const CourseHero = ({ title, image }) => {
  return (
    <StyledCourseHero>
      <div className="background">
        <Img fluid={image} draggable={false} />
      </div>
      <h1>{title}</h1>
    </StyledCourseHero>
  )
}

export default CourseHero
